html, body, #root {
	margin: 0;
	padding: 0;
	background-color: transparent !important;
	overflow: hidden;
	height: 100%;
}

@font-face {
	font-family: 'semiBold';
	src: url('../public/open-sans.semibold.ttf');
}

@font-face {
	font-family: 'extraBold';
	src: url('../public/open-sans.semibold.ttf');
}

@font-face {
	font-family: 'xxBold';
	src: url('../public/open-sans.extrabold.ttf');
}

@font-face {
    font-family: 'Inter-BoldItalic';
    src: url('../public/Inter-BoldItalic.woff');
}

@font-face {
    font-family: 'Roboto';
    src: url('../public/Roboto-Black.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.container {
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100vh;
  }
  
  .container-hidden {
	opacity: 0;
	transform: translateY(20px);
  }
  

.text-center {
	color: #fff;
	text-transform: uppercase;
	font-size: 23px;
	margin: -50px 0 80px 0;
	display: block;
	text-align: center;
}
.box {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.89);
	border-radius: 3px;
	padding: 70px 100px;
}
.input-container {
	position: relative;
	margin-bottom: 25px;
}
.input-container label {
	position: absolute;
	top: 0px;
	left: 0px;
	font-size: 16px;
	color: #fff;
	pointer-event: none;
	transition: all 0.5s ease-in-out;
}
.input-container input {
	border: 0;
	border-bottom: 1px solid #cecece;
	background: transparent;
	width: 100%;
	padding: 8px 0 5px 0;
	font-size: 16px;
	text-align: center;
	color: #cecece;
}
.input-container input:focus {
	border: none;
	outline: none;
	border-bottom: 1px solid #e74c3c;
}
.btn {
	background-color: #e74c3c;
	outline: none;
	border: 0;
	padding: 10px 20px;
	text-transform: uppercase;
	margin-top: 50px;
	border-radius: 2px;
	cursor: pointer;
	position: relative;
	z-index: 199;
}
/*.btn:after{
	content:"";
	position:absolute;
	background:rgba(0,0,0,0.50);
	top:0;
	right:0;
	width:100%;
	height:100%;
}*/
.input-container input:focus ~ label,
.input-container input:valid ~ label {
	top: -12px;
	font-size: 12px;
}

.card {
	height: 190px;
	width: 250px;
	position: relative;
	display: inline-flex;
	margin: 10px;
}
input[type='radio'] {
	-webkit-appearance: none;
	appearance: none;
	background-color: white;
	height: 100%;
	width: 100%;
	border-radius: 10px;
	position: absolute;
	box-shadow: 7px 7px 15px rgba(2, 28, 53, 0.08);
	cursor: pointer;
	outline: none;
}

.card:before {
	content: '';
	position: absolute;
	height: 22px;
	width: 22px;
	background-color: #cecece;
	border: 1px solid #cecece;
	border-radius: 50%;
	top: 35px;
	right: 20px;
}
.card:after {
	content: '';
	position: absolute;
	height: 13px;
	width: 13px;
	background-color: transparent;
	border-radius: 50%;
	top: 39.5px;
	right: 24.5px;
}
.radio-card-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	color: green;
	transform: scale(1.5);
}
label {
	position: absolute;
	margin: 20px;
	cursor: pointer;
}
h5 {
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0.5px;
	margin: 15px 0 20px 0;
}
h2 {
	font-size: 14px;
}
span {
	font-weight: 400;
	font-size: 18px;
	color: #7b7b93;
}
input[type='radio']:hover {
	transform: scale(1.05);
}
input[type='radio']:checked {
	border: 3px solid #478bfb;
}
input[type='radio']:checked:after {
	background-color: #478bfb;
}
div.formState {
	text-align: center;
	display: flex;
	margin: 0 auto;
	background-color: black;
	width: 100%;
	height: 100%;
	text-align: center;
	justify-content: center;
	align-self: center;
}

div.scoreboard {
	text-align: center;
	display: flex;
	margin: 0 auto;
	width: 100%;
	background-color: transparent !important;
	margin: 0 auto;
	text-align: center;
	justify-content: center;
	align-self: center;
}

div.matchIdInput {
	text-align: center;
	position: fixed;
	display: block;
	margin: 0 auto;
	left: 44%;
	bottom: 50%;
	height: 1rem;
	width: auto;
	z-index: 100;
}

.s0 {
	fill: none;
}

.s1 {
	fill: #5d5c5c;
}

.main-container-2-2 {
	text-align: center;
	height: 100%;
	width: 100%;
	text-align: center;
	margin-top: 7%;
}

.container-2-2 {
	text-align: center;
	text-shadow: 0.2px 0.2px 0.2px #000;
}

.container-4 {
	justify-content: center;
    align-items: center;
	/* bottom: 0; */
}

.main-container {
	display: grid;
	text-align: center;
	grid-template-columns: 10% 80% 10%;
	grid-template-rows: 50% 50%;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}
.container {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.container-exit {
    opacity: 0;
    transform: translateY(20px); /* Example: move down slightly */
}

.container-enter {
    opacity: 1;
    transform: translateY(0); /* Reset transform */
}
.container-3 {
    justify-content: center;
    align-items: center;
	/* bottom: 0; */
}


.container-1 {
    text-align: center;
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    justify-self: center;
    align-items: center;
    margin-top: -55px;
    margin-left: 4px;
    margin-bottom: 80px;
    text-shadow: 0.2px 0.2px 0.2px #000;
}


.form {
	text-align: center;
	margin: 0 auto;
	justify-content: center;
}

.container-2 {
	grid-column: 2 / 2;
	grid-row: 2 / 2;
	justify-content: center;
	align-items: center;
	margin-left: 7px;
	margin-top: 40px;
	text-shadow: 0.2px 0.2px 0.2px #000;
}

.input-field {
	width: 250px;
	z-index: 100;
}

.intro {
	text-align: center;
	justify-content: center;
	align-items: center;
	font-family: 'semiBold';
	color: white;
	font-size: 1.2rem;
}

header {
	text-align: center;
	margin-top: 3rem;
	text-align: center;
	justify-content: center;
	align-self: center;
}

h1 {
	text-align: center;
	font-family: 'semiBold';
	font-size: 3rem;
	text-decoration-line: underline;
	text-align: center;
	align-self: center;
	justify-content: center;
	color: rgb(255, 255, 255);
}

.link {
	text-align: center;
	cursor: pointer;
	color: #1677ff;
}

.single {
	text-align: center;
	position: absolute;
	color: #1677ff;
	font-family: 'semiBold';
	margin-left: 29%;
	margin-top: -0%;
}

.normal {
	opacity: 100%;
	transition: opacity 1s linear;
}

.fade {
	opacity: 20%;
	transition: opacity 1s linear;
}

a {
	color: aqua;
	font-family: 'semiBold';
}

.message {
	text-align: center;
	font-size: 1rem;
	text-align: center;
	justify-content: center;
	align-items: center;
	color: #ffffff;
}

.message {
	color: #1677ff;
}

:root {
	--bg: #242526;
	--bg-accent: #484a4d;
	--text-color: #dadce1;
	--dropdown-size: 30px;
	--border: 1px solid #474a4d;
	--border-radius: 8px;
	--speed: 500ms;
}

ul {
	text-align: center;
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	text-align: center;
	color: var(--text-color);
	text-decoration: none;
}
